import moment from 'moment'
import Model from './interface/Model'
import { tableFields } from './metadata/TaskMetadata'
import User from './User'

export default class Task extends Model {
  protected api_settings = {
    save_mode: 'post',
    paths: {
      singular: 'task' as string | null,
      plural: 'tasks' as string | null,
    },
  }

  public name: string = ''

  public description: string = ''

  public owner_id: string = ''

  public owner: User | null = null

  public target_id: string = ''

  public target: User | null = null

  public status: string = 'pending'

  public metadata: any = {}

  public messages: any = {}

  public created_at: string = ''

  public updated_at: string = ''

  public completed_at: string = ''

  public due_at: string = ''

  public get html_description() {
    const limit = 600
    const htmlText = this.description
      .replace(/^### (.*$)/gim, '<h3>$1</h3>')
      .replace(/^## (.*$)/gim, '<h2>$1</h2>')
      .replace(/^# (.*$)/gim, '<h1>$1</h1>')
      .replace(/^\> (.*$)/gim, '<blockquote>$1</blockquote>')
      .replace(/\*\*(.*)\*\*/gim, '<b>$1</b>')
      .replace(/\*(.*)\*/gim, '<i>$1</i>')
      .replace(/!\[(.*?)\]\((.*?)\)/gim, "<img alt='$1' src='$2' />")
      .replace(/\[(.*?)\]\((.*?)\)/gim, "<a href='$2' target='_blank'>$1</a>")
      .replace(/\n$/gim, '<br />')

    const ending = htmlText.length > limit ? '...' : ''
    return htmlText.trim().substring(0, limit) + ending
  }

  public get is_delayed() {
    return this.due_at && moment(this.due_at).isBefore(moment())
  }

  public get status_name() {
    return this.status !== 'completed' && this.is_delayed
      ? 'Delayed'
      : this.status.replaceAll('_', ' ')
  }

  public get status_color() {
    if (this.status === 'completed') {
      return 'success'
    }

    if (this.is_delayed) {
      return 'danger'
    }
    if (this.status === 'pending') {
      return 'secondary'
    }
    if (this.status === 'in_progress') {
      return 'info'
    }

    return 'warning'
  }

  public static tableFields: any = tableFields
}
