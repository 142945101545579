
















































import {
  Vue, Prop, Component, Watch,
} from 'vue-property-decorator'
import DataForm from '@/components/DataForm/DataForm.vue'
import Task from '@/models/Task'
import { getModule } from 'vuex-module-decorators'
import SystemtModule from '@/store/SystemModule'
import { clone as _clone } from 'lodash'
import IconAction from '@/components/IconAction/IconAction.vue'
import WebMessage from '@/models/WebMessage'
import formData from '../form'

@Component({
  components: {
    DataForm,
    IconAction,
  },
})
export default class EditTask extends Vue {
  @Prop({ default: null })
  public id!: string | null

  public task: Task = new Task()

  public busy = false

  @Prop({ default: null })
  public dataTable!: any

  @Prop({ default: null })
  public show!: any

  public edit: boolean = false

  private get showModal() {
    return this.show === null ? true : this.show
  }

  private set showModal(show: boolean) {
    this.$emit('update:show', show)
  }

  public form = formData

  private updateForm() {
    this.busy = true
    let form = _clone(formData)
    form.sections[0].fields = form.sections[0].fields.map((field: any) => {
      field.edit = this.edit
      return field
    })

    this.form = form
    setTimeout(() => {
      this.busy = false
    }, 10)
  }

  private get user() {
    return getModule(SystemtModule).user
  }

  private editTask() {
    this.edit = true
    this.updateForm()
  }

  public confirm() {
    if (this.edit) {
      this.task.save().then(() => {
        if (this.dataTable) this.dataTable.refresh()
        if (this.show === null) {
          this.$destroy()
          this.$el.parentNode?.removeChild(this.$el)
        }
      })
    } else {
      this.$destroy()
      this.$el.parentNode?.removeChild(this.$el)
    }
  }

  public cancel() {
    if (this.show === null) {
      this.$destroy()
      this.$el.parentNode?.removeChild(this.$el)
    }
  }

  @Watch('show')
  public onShowChange() {
    if (this.show) {
      this.updateTask()
    }
  }

  public created() {
    this.updateForm()
    this.updateTask()
  }

  public updateTask() {
    if (this.id) {
      this.busy = true
      this.edit = false
      Task.find(this.id).then(task => {
        if (!task) {
          this.cancel()
          WebMessage.error('Task not found!')
          return
        }
        this.task = task
        this.updateForm()
      })
    } else {
      if (this.user && typeof this.user.id === 'string') {
        this.task.target_id = this.user.id
      }
      this.edit = true
      this.updateForm()
    }
  }
}
