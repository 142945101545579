









































































import {
  Component, Vue, Prop, Watch,
} from 'vue-property-decorator'
import Notifications from '@/components/Notifications/Notifications.vue'
import { getModule } from 'vuex-module-decorators'
import SystemtModule from '@/store/SystemModule'
import Api from '@/models/Api'
import ViewModel from '../../models/ViewModel'

@Component({
  components: {
    Notifications,
  },
})
export default class Header extends ViewModel {
  public logout() {
    this.system.logout()
  }

  public get notifications() {
    return getModule(SystemtModule).notifications.filter(
      (notification: any) => notification.read_at === null,
    ).length
  }

  public switchSidebarMethod() {
    if (!this.layout.sidebarClose) {
      this.layout.switchSidebar(true)
      this.layout.changeSidebarActive(null)
    } else {
      this.layout.switchSidebar(false)
      const paths = this.$route.fullPath.split('/')
      paths.pop()
      this.layout.changeSidebarActive(paths.join('/'))
    }
  }

  public toggleSidebarMethod() {
    if (this.layout.sidebarStatic) {
      this.layout.toggleSidebar()
      this.layout.changeSidebarActive(null)
    } else {
      this.layout.toggleSidebar()
      const paths = this.$route.fullPath.split('/')
      paths.pop()
      this.layout.changeSidebarActive(paths.join('/'))
    }
  }

  public markAsRead() {
    const api = new Api(false)
    api.get('notifications/mark-as-read').then(() => {
      const system = getModule(SystemtModule)
      const notifications = system.notifications.map((notification: any) => {
        notification.read_at = true
        return notification
      })
      setTimeout(() => {
        system.updateState({
          name: 'notifications',
          data: notifications,
        })
      }, 5000)
    })
  }

  public myAccount() {
    this.$router.push('/app/account')
  }
}
