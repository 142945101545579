


























































import Api from '@/models/Api'
import SystemtModule from '@/store/SystemModule'
import { Component, Vue } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'
import EditTask from '@/pages/Task/components/EditTask.vue'

@Component({})
export default class Notification extends Vue {
  private loading: boolean = false

  public get notifications() {
    return getModule(SystemtModule).notifications
  }

  public refreshNotifications() {
    Vue.set(this, 'isLoad', true)

    setTimeout(() => {
      Vue.set(this, 'newNotifications', 'new notifications component')
      Vue.set(this, 'isLoad', false)
    }, 1500)
  }

  public clearNotifications() {
    const system = getModule(SystemtModule)
    system.updateState({
      name: 'notifications',
      data: [],
    })
    const api = new Api(false)
    this.loading = true
    api.get('notifications/clear').then(() => {
      this.loading = false
    })
  }

  public limitCharacters(text: string) {
    const limit = 50
    return text.length > limit ? `${text.substring(0, limit)}...` : text
  }

  public onClick(notification: any) {
    if (notification.id) {
      let Component = Vue.extend(EditTask)
      let instance = new Component({
        propsData: {
          id: notification.id,
        },
      })
      instance.$mount()
    }
  }

  public createPlan(notification: any) {
    this.$router.push(
      `/app/sales/media_plan/?sf_opporttunity=${notification.data.context.metadata.origin_id}`,
    )
  }
}
