


























import {
  Component, Vue, Prop, Watch,
} from 'vue-property-decorator'
import ProfileForm from '@/pages/Profile/Components/ProfileForm.vue'
import ViewModel from '@/models/ViewModel'

@Component({
  components: {
    ProfileForm,
  },
})
export default class ProfilePanel extends ViewModel {
  public new_id: string | null = null;

  public local_show: boolean = false;

  public local_payload: any = {
    company_id: null,
    station_id: null,
  };

  public get payload(): any {
    return this.layout.side_panel_data
  }

  public get show(): boolean {
    return this.layout.show_side_panel
  }

  @Watch('payload')
  public onChangePayload() {
    if (this.payload) {
      this.local_payload.company_id = this.payload.company_id
      this.local_payload.station_id = this.payload.station_id
    }
  }

  @Watch('show')
  public onChangeGlobalStatus() {
    this.local_show = this.show
  }

  @Watch('local_show')
  public onChangLocalStatus() {
    if (!this.local_show) {
      this.layout.hideSidePanel(this.new_id)
    }
  }

  public viewPanel(name: string): boolean {
    return this.user.can(name, 'edit') && this.layout.panel_action == name
  }
}
