import { getModule } from 'vuex-module-decorators'
import SystemtModule from '@/store/SystemModule'
import EditTask from '@/pages/Task/components/EditTask.vue'
import Vue from 'vue'
import Task from '../Task'
import WebMessage from '../WebMessage'

const { user } = getModule(SystemtModule)

export const model_name = 'task'

export const querySettings = {
  user_fields: [
    {
      name: 'target',
      key: 'target_id',
    },
    {
      name: 'owner',
      key: 'owner_id',
    },
  ],
  custom_fields: [
    {
      name: 'is:mine',
      value: 'is:mine',
    },
    {
      name: 'is:owned',
      value: 'is:owned',
    },
    {
      name: 'is:assigned',
      value: 'is:assigned',
    },
    {
      name: 'is_not:completed',
      value: 'is_not:completed',
    },
  ],
}

export const tableFields: any = [
  /* {
    key: 'checkbox',
    label: '',
    sortable: false,
    show: true,
    type: 'checkbox',
  }, */
  {
    key: 'id',
    label: 'ID',
    sortable: true,
    show: false,
    tdClass: 'align-middle',
  },
  {
    key: 'name',
    label: 'Name',
    sortable: true,
    show: true,
    tdClass: 'align-middle text-center',
  },
  {
    key: 'html_description',
    label: 'Description',
    sortable: true,
    show: false,
    tdClass: 'align-middle',
  },
  {
    key: 'owner',
    label: 'Owner',
    sortable: true,
    show: true,
    type: 'object',
    object: {
      key: 'owner',
      property: 'name',
    },
    tdClass: 'align-middle text-center',
  },
  {
    key: 'target',
    label: 'Assigned To',
    sortable: true,
    show: true,
    type: 'object',
    object: {
      key: 'target',
      property: 'name',
    },
    tdClass: 'align-middle text-center',
  },
  {
    key: 'status_name',
    label: 'Status',
    sortable: true,
    show: true,
    type: 'badge',
    color: (task: Task) => task.status_color,
    tdClass: 'align-middle text-center',
  },
  {
    key: 'due_at',
    label: 'Due At',
    sortable: true,
    show: true,
    type: 'date',
    tdClass: 'align-middle text-center',
  },
  {
    key: 'created_at',
    label: 'Created At',
    sortable: true,
    show: false,
    type: 'date',
    tdClass: 'align-middle text-center',
  },
  {
    key: 'updated_at',
    label: 'Update At',
    sortable: true,
    show: true,
    type: 'date',
    tdClass: 'align-middle text-center',
  },
  {
    key: 'action',
    label: '',
    sortable: false,
    show: true,
    type: 'action_list',
    actions: [
      {
        icon: 'search',
        title: 'View',
        event: 'view',
        show: (task: Task) => user && user.can('task', 'view'),
        action: (task: Task, dataTable: any) => {
          let Component = Vue.extend(EditTask)
          let instance = new Component({
            propsData: {
              id: task.id,
              dataTable,
            },
          })
          instance.$mount()
        },
      },
      {
        icon: 'play',
        title: 'Start',
        event: 'start',
        show: (task: Task) =>
          user
          && user.can('task', 'edit')
          && ['completed', 'pending', 'hold'].includes(task.status),
        action: (task: Task, dataTable: any) => {
          task.status = 'in_progress'
          task.save().then(() => {
            dataTable.refresh()
          })
        },
      },
      {
        icon: 'check',
        title: 'Complete',
        event: 'complete',
        show: (task: Task) =>
          user
          && user.can('task', 'edit')
          && ['in_progress', 'pending', 'hold'].includes(task.status),
        action: (task: Task, dataTable: any) => {
          task.status = 'completed'
          task.save().then(() => {
            dataTable.refresh()
          })
        },
      },
      {
        icon: 'pause',
        title: 'Hold',
        event: 'hold',
        show: (task: Task) =>
          user && user.can('task', 'edit') && ['in_progress', 'pending'].includes(task.status),
        action: (task: Task, dataTable: any) => {
          task.status = 'hold'
          task.save().then(() => {
            dataTable.refresh()
          })
        },
      },
      {
        icon: 'times',
        title: 'Delete',
        event: 'delete',
        show: (task: Task) => user && user.can('task', 'edit') && ['pending'].includes(task.status),
        action: (task: Task, dataTable: any) => {
          WebMessage.confirm(
            `Are you sure that you want to delete the Task "<strong>${task.name}</strong>"? You won't be able to restore it!`,
            'Delete Task',
          ).then((value: boolean) => {
            if (value) {
              task.delete().then(() => {
                dataTable.refresh()
              })
            }
          })
        },
      },
    ],
  },
]
