


























import {
  Component, Vue, Prop, Watch,
} from 'vue-property-decorator'
import CompanyForm from '@/pages/Company/components/CompanyForm.vue'
import ViewModel from '@/models/ViewModel'

@Component({
  components: {
    CompanyForm,
  },
})
export default class CompanyPanel extends ViewModel {
  public new_id: string | null = null;

  public local_show: boolean = false;

  public local_payload: any = {
    owner_id: null,
    type: null,
  };

  public get payload(): any {
    return this.layout.side_panel_data
  }

  public get show(): boolean {
    return this.layout.show_side_panel
  }

  @Watch('payload')
  public onChangePayload() {
    if (this.payload) {
      this.local_payload.owner_id = this.payload.owner_id
      this.local_payload.type = this.payload.type
    }
  }

  @Watch('show')
  public onChangeGlobalStatus() {
    this.local_show = this.show
  }

  @Watch('local_show')
  public onChangLocalStatus() {
    if (!this.local_show) {
      this.layout.hideSidePanel(this.new_id)
    }
  }

  public viewPanel(name: string): boolean {
    return this.user.can(name, 'edit') && this.layout.panel_action == name
  }
}
