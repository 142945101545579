import { getModule } from 'vuex-module-decorators'
import SystemtModule from '@/store/SystemModule'

const { user } = getModule(SystemtModule)

export default [
  {
    title: 'Insights',
    icon: 'dashboard',
    link: '/app/insights',
    index: 'insights',
    acl: {
      model: 'insights',
      action: 'view',
    },
  },
  {
    title: 'Reports',
    icon: 'chart-line',
    link: () => {
      if (user && user.can('report', 'edit')) {
        return '/app/reports'
      }
      return '/app/report'
    },
    index: 'reports',
    acl: {
      model: 'report',
      action: 'view',
    },
  },
  {
    title: 'Planning',
    icon: 'briefcase',
    index: 'sales',
    children: [
      {
        title: 'Media Packages',
        icon: 'box',
        link: '/app/sales/media_packages',
        index: 'media_packages',
        acl: {
          model: 'media_package',
          action: 'view',
        },
      },
      {
        title: 'Media Plan',
        icon: 'project-diagram',
        link: '/app/sales/media_plans',
        index: 'media_plan',
        acl: {
          model: 'media_plan',
          action: 'view',
        },
      },
      {
        title: 'Dynamic Rate',
        icon: 'magic',
        link: '/app/sales/dynamic_rates',
        index: 'dynamic_rates',
        label: 'beta',
        acl: {
          model: 'dynamic_rate',
          action: 'view',
        },
      },
    ],
  },
  {
    title: 'Ad Ops',
    icon: 'toolbox',
    index: 'adops',
    children: [
      {
        title: 'Order Monitoring',
        icon: 'tools',
        link: '/app/adops/monitoring',
        index: 'order_monitoring',
        acl: {
          model: 'adops',
          action: 'view',
        },
      },
      {
        title: 'Impressions Distribution',
        icon: 'broadcast-tower',
        link: '/app/adops/distribution',
        index: 'inventory_distribution',
        acl: {
          model: 'adops',
          action: 'view',
        },
      },
    ],
  },
  {
    title: 'Legal',
    icon: 'gavel',
    index: 'legal',
    children: [
      /* {
        title: 'Agreements',
        icon: 'file-signature',
        link: '/app/agreements',
        index: 'agreements',
        acl: {
          model: 'agreement',
          action: 'view',
        },
      },
      {
        title: 'IOs',
        icon: 'file',
        link: '/app/insertion_orders',
        index: 'insertion_orders',
        acl: {
          model: 'insertion_order',
          action: 'view',
        },
      }, */
      {
        title: 'Terms',
        icon: 'file-contract',
        link: '/app/terms',
        index: 'terms',
        acl: {
          model: 'term',
          action: 'view',
        },
      },
    ],
  },
  {
    title: 'Delivery',
    icon: 'truck',
    index: 'delivery',
    children: [
      {
        title: 'Creatives',
        icon: 'video',
        link: '/app/creatives',
        index: 'creatives',
        acl: {
          model: 'creative',
          action: 'view',
        },
      },
    ],
  },
  {
    title: 'Inventory',
    icon: 'warehouse',
    index: 'inventory',
    children: [
      {
        title: 'Ad Units',
        icon: 'ad',
        link: '/app/inventories',
        index: 'adunits',
        acl: {
          model: 'inventory',
          action: 'view',
        },
      },
      /* {
        title: 'Forecast',
        icon: 'bullseye',
        link: '/app/forecast',
        index: 'forecast',
        acl: {
          model: 'forecast',
          action: 'view',
        },
      }, */
      {
        title: 'Schedulers',
        icon: 'stopwatch',
        link: '/app/schedulers',
        index: 'schedulers',
        acl: {
          model: 'scheduler',
          action: 'view',
        },
      },
    ],
  },
  {
    title: 'Finance',
    icon: 'dollar-sign',
    index: 'Finance',
    children: [
      {
        title: 'Reconciliation',
        icon: 'receipt',
        link: '/app/reconciliations',
        index: 'reconciliations',
        label: 'beta',
        acl: {
          model: 'reconciliation',
          action: 'view',
        },
      },
      /* {
        title: 'Closeouts',
        icon: 'calendar',
        link: '/app/closeouts',
        index: 'closeouts',
        acl: {
          model: 'closeout',
          action: 'view',
        },
      },
      {
        title: 'Invoices',
        icon: 'file-invoice-dollar',
        link: '/app/invoices',
        index: 'invoices',
        acl: {
          model: 'invoice',
          action: 'view',
        },
      }, */
      {
        title: 'Media Ocean',
        icon: 'file-invoice-dollar',
        link: '/app/mediaocean_invoices',
        index: 'mediaocean_invoices',
        acl: {
          model: 'media_ocean',
          action: 'view',
        },
      },
    ],
  },
  {
    title: 'Admin',
    icon: 'cog',
    index: 'admin',
    children: [
      {
        title: 'Task',
        icon: 'tasks',
        link: '/app/tasks',
        index: 'tasks',
        acl: {
          model: 'task',
          action: 'view',
        },
      },
      {
        title: 'Business Entities',
        icon: 'building',
        link: '/app/companies',
        index: 'companies',
        acl: {
          model: 'company',
          action: 'view',
        },
      },
      {
        title: 'Profiles',
        icon: 'key',
        link: '/app/profiles',
        index: 'profiles',
        acl: {
          model: 'profile',
          action: 'view',
        },
      },
      {
        title: 'Report Views',
        icon: 'object-group',
        link: '/app/views',
        index: 'views',
        acl: {
          model: 'report_view',
          action: 'view',
        },
      },
      {
        title: 'Users',
        icon: 'user',
        link: '/app/users',
        index: 'users',
        acl: {
          model: 'user',
          action: 'view',
        },
      },
      {
        title: 'Audit',
        icon: 'shield',
        link: '/app/audit',
        index: 'audit',
        acl: {
          model: 'audit',
          action: 'view',
        },
      },
    ],
  },
]
