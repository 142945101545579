























































import { Component } from 'vue-property-decorator'
import isScreen from '@/core/screenHelper'
import NavLink from './NavLink/NavLink.vue'
import ViewModel from '../../models/ViewModel'
import menu from './menu'

@Component({
  components: {
    NavLink,
  },
})
export default class Sidebar extends ViewModel {
  public menu: any = []

  public sidebar_active: boolean = false

  public alerts = []

  public setActiveByRoute() {
    const paths = this.$route.fullPath.split('/')
    paths.pop()
    this.layout.changeSidebarActive(paths.join('/'))
  }

  public sidebarMouseEnter() {
    if (!this.layout.sidebarStatic && (isScreen('lg') || isScreen('xl'))) {
      this.layout.switchSidebar(false)
      this.setActiveByRoute()
    }
  }

  public sidebarMouseLeave() {
    if (!this.layout.sidebarStatic && (isScreen('lg') || isScreen('xl'))) {
      this.layout.switchSidebar(true)
      this.layout.changeSidebarActive(null)
    }
  }

  public created() {
    this.setActiveByRoute()
  }

  public get enabledMenus() {
    return menu
      .map(item => {
        if (item.children !== undefined) {
          item.children = item.children.filter(
            child => child.acl === undefined || this.user.can(child.acl.model, child.acl.action),
          )
        }
        return item
      })
      .filter(
        (link: any) =>
          (link.children === undefined
            && (!link.acl || this.user.can(link.acl.model, link.acl.action)))
          || link.children.some((c: any) => !c.acl || this.user.can(c.acl.model, c.acl.action)),
      )
  }
}
