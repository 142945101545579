import SelectOption from '@/models/interface/SelectOption'

export default {
  name: 'Task',
  sections: [
    {
      name: 'Task',
      fields: [
        {
          name: 'Title',
          type: 'text',
          target: 'name',
          rules: 'required|min:3|max:255',
        },
        {
          name: 'Description',
          type: 'html',
          target: 'description',
          rules: 'required',
        },
        {
          name: 'Assign To',
          type: 'user',
          target: 'target_id',
          rules: 'required',
          create: false,
        },
        {
          name: 'Due At',
          type: 'datetime',
          target: 'due_at',
          clearable: true,
          rules: '',
        },
        {
          name: 'Status',
          type: 'picker',
          target: 'status',
          rules: 'required',
          options: [
            new SelectOption('Pending', 'pending'),
            new SelectOption('In Progress', 'in_progress'),
            new SelectOption('Completed', 'completed'),
            new SelectOption('Hold', 'hold'),
          ],
        },
      ],
    },
  ],
}
