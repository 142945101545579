




























































import { Component } from 'vue-property-decorator'

import Sidebar from '@/components/Sidebar/Sidebar.vue'
import Header from '@/components/Header/Header.vue'
import Helper from '@/components/Helper/Helper.vue'
// @ts-ignore
import offline from 'v-offline'
import { getModule } from 'vuex-module-decorators'
import SystemtModule from '@/store/SystemModule'
import store from '../../store'
import LayoutModule from '../../store/LayoutModule'
import CompanyPanel from '../SidePanels/CompanyPanel.vue'
import UserPanel from '../SidePanels/UserPanel.vue'
import CreativePanel from '../SidePanels/CreativePanel.vue'
import ProfilePanel from '../SidePanels/ProfilePanel.vue'
import ViewModel from '../../models/ViewModel'

@Component({
  components: {
    Sidebar,
    Header,
    Helper,
    offline,
    CompanyPanel,
    UserPanel,
    CreativePanel,
    ProfilePanel,
  },
})
export default class Layout extends ViewModel {
  public get dashboardThemeClass() {
    return `dashboard-${this.layout.dashboardTheme}`
  }

  public get app_env(): string {
    return getModule(SystemtModule).enviroment
  }

  public handleWindowResize() {
    const width = window.innerWidth

    if (width <= 768 && this.layout.sidebarStatic) {
      this.layout.toggleSidebar()
      this.layout.changeSidebarActive(null)
    }
  }

  public handleSwipe(e: any) {
    this.layout.handleSwipe(e)
  }

  public handleConnectivityChange(status: any) {
    this.system.updateNetworkStatus(status)
  }

  public created() {
    const layout = getModule(LayoutModule, store)
    // @ts-ignore
    const staticSidebar = JSON.parse(localStorage.getItem('sidebarStatic'))

    if (staticSidebar) {
      this.$store.state.layout.sidebarStatic = true
    } else if (!this.layout.sidebarClose) {
      setTimeout(() => {
        layout.switchSidebar(true)
        layout.changeSidebarActive(null)
      }, 2500)
    }

    this.handleWindowResize()
    window.addEventListener('resize', this.handleWindowResize)
  }

  public beforeDestroy() {
    window.removeEventListener('resize', this.handleWindowResize)
  }
}
